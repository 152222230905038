import React from "react";
import tg from "../../assets/images/tg-2.svg";
import reddit from "../../assets/images/reddit-2.svg";
import tw from "../../assets/images/tw-2.svg";

export default function Community() {
  return (
    <section
      className="py-[100px]"
      data-aos="fade-left"
      data-aos-duration="800">
      <div className="max-w-3xl mx-auto text-center px-5">
        <h3 className="text-gradient text-3xl font-bold">
          Join the growing community
        </h3>
        <p className="mt-4">
          Join our active community where you can discuss with our team and
          learn more about NudeCoin. Help us shape the future of NudeCoin
        </p>
        <div className="mt-12 grid grid-cols-1 gap-8 lg:grid-cols-3">
          <a
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center justify-center text-center py-7 px-5 border-2 border-[#d1d4da] shadow-custom rounded-xl transition-all hover:border-[#FF00B9]">
            <a className="max-w-[70px]">
              <img src={reddit} alt="" />
            </a>
            <h5 className="font-bold text-link my-3">Reddit</h5>
            <p className="text-sm">Join the community on reddit</p>
          </a>
          <a
            href="https://twitter.com/NudeCoinERC"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center justify-center text-center py-7 px-5 border-2 border-[#d1d4da] shadow-custom rounded-xl transition-all hover:border-[#FF00B9]">
            <a className="max-w-[70px]">
              <img src={tw} alt="" />
            </a>
            <h5 className="font-bold text-link my-3">Twitter</h5>
            <p className="text-sm">Follow for the latest updates and news</p>
          </a>
          <a
            href="https://t.me/NUDECOINERC"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center justify-center text-center py-7 px-5 border-2 border-[#d1d4da] shadow-custom rounded-xl transition-all hover:border-[#FF00B9]">
            <a className="max-w-[70px]">
              <img src={tg} alt="" />
            </a>
            <h5 className="font-bold text-link my-3">Telegram</h5>
            <p className="text-sm">Join the community on reddit</p>
          </a>
        </div>
      </div>
    </section>
  );
}
