import { Routes, Route, Link, useLocation, NavLink } from "react-router-dom";
import AOS from "aos";

import "aos/dist/aos.css";

import Home from "./pages/Home";
import Whitepaper from "./pages/Whitepaper";

import logo from "./assets/images/logo.svg";
import logowhite from "./assets/images/logo-white.svg";
import intro from "./assets/images/intro.svg";
import ins from "./assets/images/ins.svg";
import tw from "./assets/images/tw.svg";
import ytb from "./assets/images/ytb.svg";
import twitch from "./assets/images/twitch.svg";
import tg from "./assets/images/tg.svg";
import reddit from "./assets/images/reddit.svg";
import discord from "./assets/images/discord.svg";
import copy from "./assets/images/copy.svg";
import pinksale from "./assets/images/pinksale.png";
import dexview from "./assets/images/dexview.webp";

import "./App.css";
import { useEffect } from "react";

const CONTRACT = "0x2C9cBE6638E4B2643585C6f826Cb864Be96D2989";

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 60,
    });
  }, []);

  return (
    <div className="App">
      <header className="bg-[url('./assets/images/bg-header.png')] bg-no-repeat bg-cover bg-bottom">
        <div className="container mx-auto px-5 lg:px-16">
          <div className="flex flex-col gap-5 justify-between items-center py-[100px] lg:flex-row">
            <Link to="/" className="max-w-[150px]">
              <img src={logo} alt="" />
            </Link>
            <div className="flex flex-col gap-5 items-center lg:gap-10 lg:flex-row">
              <div className="flex gap-4 text-sm text-link font-bold lg:gap-8 md:text-base">
                <NavLink activeClassName="link-active" to="/">
                  Livecam
                </NavLink>
                <NavLink activeClassName="link-active" to="/whitepaper">
                  White Paper
                </NavLink>
                <a
                  href="https://www.dexview.com/eth/0x28FBa7Fe6d4CF1081b292d0226818378DcAabf6f"
                  target="_blank"
                  rel="noopener noreferrer">
                  How to Buy
                </a>
                <Link>Our Team</Link>
              </div>
              <a
                className="btn btn-primary"
                href="#"
                target="_blank"
                rel="noopener noreferrer">
                Livecam App
              </a>
            </div>
          </div>
          <div
            className="flex flex-col-reverse gap-16 pb-[100px] lg:flex-row"
            data-aos="fade-up"
            data-aos-duration="800">
            {location.pathname === "/" ? (
              <>
                <div>
                  <h1 className="max-w-2xl font-bold text-gradient text-3xl lg:text-5xl">
                    Pioneering the Fusion of Cryptocurrency and Adult
                    Entertainment Innovation
                  </h1>
                  <div className="flex gap-5 mt-10">
                    <a
                      href="https://www.dexview.com/eth/0x28FBa7Fe6d4CF1081b292d0226818378DcAabf6f"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary">
                      CHART
                    </a>
                    <Link to="/whitepaper" className="btn btn-secondary">
                      <span className="text-gradient">WHITE PAPER</span>
                    </Link>
                  </div>
                </div>
                <div className="max-w-[200px] lg:max-w-lg">
                  <img src={intro} alt="" />
                </div>
              </>
            ) : (
              <div>
                <h2 className="font-bold text-gradient text-3xl lg:text-7xl">
                  White Paper
                </h2>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-buy mt-7 inline-flex">
                  Buy $NUDE
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="bg-[url('./assets/images/rec.svg')] bg-no-repeat bg-cover w-full absolute top-0 py-2 pr-[10%]">
          <div className="flex justify-end gap-5">
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src={ins} alt="" />
            </a>
            <a
              href="https://twitter.com/NudeCoinERC"
              target="_blank"
              rel="noopener noreferrer">
              <img src={tw} alt="" />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src={ytb} alt="" />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src={twitch} alt="" />
            </a>
            <a
              href="https://t.me/NUDECOINERC"
              target="_blank"
              rel="noopener noreferrer">
              <img src={tg} alt="" />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src={reddit} alt="" />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src={discord} alt="" />
            </a>
          </div>
        </div>
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/whitepaper" element={<Whitepaper />} />
        </Routes>
      </main>

      <footer className="bg-primary py-11 text-white">
        <div className="container mx-auto px-5 lg:px-16">
          <div className="flex flex-col justify-between gap-8 pb-8 lg:flex-row">
            <div>
              <div className="max-w-[140px]">
                <img src={logowhite} alt="" />
              </div>
              <div
                className="mt-3 mb-6 bg-white border-[1px] border-[#d1d4da] flex justify-between items-center gap-5 py-3 px-5 rounded-3xl cursor-pointer text-primary"
                onClick={() => {
                  navigator.clipboard.writeText(CONTRACT);
                }}>
                <span>
                  {CONTRACT?.substring(0, 6)}...
                  {CONTRACT?.substring(CONTRACT.length - 6)}
                </span>
                <div className="w-[20px] h-[20px]">
                  <img src={copy} alt="" />
                </div>
              </div>
              <span className="block text-sm">hello@nudecoin.bio</span>
            </div>
            <div className="grid grid-cols-3 gap-[50px] md:gap-[150px]">
              <div className="">
                <h5 className="font-bold">Community</h5>
                <ul className="text-sm mt-3">
                  <li>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      Discord
                    </a>
                  </li>
                  <li className="mt-3">
                    <a
                      href="https://t.me/NUDECOINERC"
                      target="_blank"
                      rel="noopener noreferrer">
                      Telegram
                    </a>
                  </li>
                  <li className="mt-3">
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      Reddit
                    </a>
                  </li>
                </ul>
              </div>
              <div className="">
                <h5 className="font-bold">Social</h5>
                <ul className="text-sm mt-3">
                  <li>
                    <a
                      href="https://twitter.com/NudeCoinERC"
                      target="_blank"
                      rel="noopener noreferrer">
                      Twitter
                    </a>
                  </li>
                  <li className="mt-3">
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
              <div className="">
                <h5>Resouces</h5>
                <ul className="text-sm mt-3">
                  <li>
                    <Link to="/whitepaper">White Paper</Link>
                  </li>
                  <li className="mt-3">
                    <a
                      href="https://www.dexview.com/eth/0x28FBa7Fe6d4CF1081b292d0226818378DcAabf6f"
                      target="_blank"
                      rel="noopener noreferrer">
                      How to Buy
                    </a>
                  </li>
                  <li className="mt-3">
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      Chart
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center gap-5 lg:justify-start">
            <span>Support by:</span>
            <ul className="flex items-center gap-4">
              <li>
                <a
                  href="https://www.dexview.com/eth/0x28FBa7Fe6d4CF1081b292d0226818378DcAabf6f"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img className="max-w-[50px]" src={dexview} alt="dexview" />
                </a>
              </li>
            </ul>
          </div>
          <p className="flex flex-col text-center gap-5 mt-8 text-sm lg:gap-16 lg:flex-row lg:mt-16">
            <span>Copyright 2023 NudeCoin</span>
            <span>Disclaimer</span>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
