import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import Partners from "../../components/Partners";
import Community from "../../components/community";

import pn1 from "../../assets/images/pn-1.svg";
import pn2 from "../../assets/images/pn-2.svg";
import pn3 from "../../assets/images/pn-3.svg";

const settings = {
  dots: false,
  infinite: true,
  speed: 6000,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 0,
  pauseOnHover: false,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        arrows: false,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 2,
      },
    },
  ],
};

export default function Home() {
  return (
    <div>
      <section className="py-12">
        <div
          className="container mx-auto px-16"
          data-aos="fade-up"
          data-aos-duration="800">
          <Partners />
        </div>
      </section>

      <section
        className="py-12 max-w-5xl mx-auto px-5"
        data-aos="fade-up"
        data-aos-duration="800">
        <h3 className="text-3xl text-primary font-bold">About Us</h3>
        <p className="text-gray mt-3 leading-7">
          NudeCoin ($NUDE) is a transformative force in the adult entertainment
          industry, bridging the gap between traditional and digital currencies.
          Harnessing the power of blockchain, we've developed a unique platform
          that brings together engaging features such as live cams, fan pages,
          tipping, and messaging. Our innovative fiat-to-crypto payment system
          makes transactions seamless, ensuring both crypto-savvy users and
          those new to digital currencies can benefit from our platform.
          Partnering with a spectrum of talents, from newcomers to top creators,
          we're all about empowering individuals and elevating experiences.
        </p>
      </section>

      <Community />

      <section className="py-12">
        <div className="container mx-auto px-5 lg:px-16">
          <div className="flex flex-col gap-16 xl:flex-row">
            <div
              className="w-full xl:max-w-2xl"
              data-aos="fade-right"
              data-aos-duration="800">
              <h3 className="text-3xl text-gradient font-bold max-w-md">
                LiveCam is coming to the blockchain!
              </h3>
              <p className="leading-7">
                <h5 className="text-link font-bold mt-7 mb-2 text-xl">
                  Our Vision
                </h5>
                Our vision at NudeCoin is to create a sustainable and inclusive
                ecosystem in the adult entertainment industry. We aim to break
                down traditional barriers, providing equal opportunities to all
                talents while challenging banking's conventional norms. By
                leveraging the power of cryptocurrency, we strive to bring
                privacy and financial inclusivity to the forefront, making adult
                entertainment a space where talent thrives, users enjoy seamless
                transactions, and cryptocurrency takes center stage.
                <h5 className="text-link font-bold mt-7 mb-2 text-xl">
                  Our Token
                </h5>
                NudeCoin ($NUDE) stands at the heart of our transformative adult
                entertainment platform. It is an integral part of our innovative
                payment system, providing a seamless fiat-to-crypto transaction
                experience. As users purchase website credits, $NUDE is bought
                and burned behind the scenes, enhancing its value. Coin holders
                can directly send $Nude to the site, exchanging it for usable
                credits. The deflationary nature of $NUDE, coupled with its
                critical role in our ecosystem, makes it a promising token in
                the crypto space.
              </p>
            </div>
            <div data-aos="fade-left" data-aos-duration="800">
              <div className="flex gap-10">
                <span className="inline-flex justify-center items-center w-[40px] h-[30px] rounded bg-primary text-white flex-shrink-0">
                  Q1
                </span>
                <ul className="list-disc ">
                  <li>
                    Finish building our live cam website and enroll Camgirls and
                    build an audience
                  </li>
                  <li>Conceptualization of NudeCoin and our payment system</li>
                  <li>Connect with KOLs and advisors in the space. ✓</li>
                </ul>
              </div>
              <div className="flex gap-10 mt-8">
                <span className="inline-flex justify-center items-center w-[40px] h-[30px] rounded bg-primary text-white flex-shrink-0">
                  Q2
                </span>
                <ul className="list-disc ">
                  <li>Launch of NudeCoin Website and Telegram ✓</li>
                  <li>Locked liquidity</li>
                  <li>
                    Achieve tracking by Coingecko CoinMarketCap, Blockfolio, &
                    Coinbase
                  </li>
                  <li>
                    Kick-off aggressive marketing campaign and expand social
                    media presence
                  </li>
                  <li>
                    Roll out innovative fiat to cryptocurrency payment system ✓
                  </li>
                  <li>
                    Partner with top adult content creators to feature on our
                    platform
                  </li>
                  <li>
                    Introduce NudeCoin's live cam services to investors in
                    Nudecoin
                  </li>
                  <li>Undergo security auditing</li>
                  <li>Kraken and Kucoin Listings</li>
                </ul>
              </div>
              <div className="flex gap-10 mt-8">
                <span className="inline-flex justify-center items-center w-[40px] h-[30px] rounded bg-primary text-white flex-shrink-0">
                  Q3
                </span>
                <ul className="list-disc ">
                  <li>
                    Launch our fully-featured platform where $NUDE is used for
                    tipping, messaging, and accessing exclusive content
                  </li>
                  <li>
                    Launch marketing campaign with exclusive benefits for those
                    early models who migrate to the platform
                  </li>
                  <li>
                    Solidify the NudeCoin brand in the adult entertainment
                    industry
                  </li>
                  <li>
                    Solidify the NudeCoin brand in the adult entertainment
                    industry
                  </li>
                </ul>
              </div>
              <div className="flex gap-10 mt-8">
                <span className="inline-flex justify-center items-center w-[40px] h-[30px] rounded bg-primary text-white flex-shrink-0">
                  Q4
                </span>
                <ul className="list-disc ">
                  <li>Automate the KYC process for new creators</li>
                  <li>
                    Continue collaborations and outreach to high-profile names
                    in the adult industry
                  </li>
                  <li>
                    Introduce incentives for holding a specific amount of $NUDE
                  </li>
                  <li>
                    Execute a buyback and burn with a percentage of the profits
                    generated from the platform
                  </li>
                  <li>
                    Undertake charity initiatives using profits generated to
                    support victims of adult industry exploitation.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-[100px]">
        <Slider {...settings} className="flex items-center gap-10">
          <div className="max-w-[250px] h-[33px] slider-item-custom">
            <img src={pn1} alt="" />
          </div>
          <div className="max-w-[250px] h-[33px] slider-item-custom">
            <img src={pn2} alt="" />
          </div>
          <div className="max-w-[250px] h-[33px] slider-item-custom">
            <img src={pn3} alt="" />
          </div>
          <div className="max-w-[250px] h-[33px] slider-item-custom">
            <img src={pn1} alt="" />
          </div>
          <div className="max-w-[250px] h-[33px] slider-item-custom">
            <img src={pn2} alt="" />
          </div>
          <div className="max-w-[250px] h-[33px] slider-item-custom">
            <img src={pn3} alt="" />
          </div>
        </Slider>
      </section>
    </div>
  );
}
