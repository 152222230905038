import React from "react";
import blockfolio from "../../assets/images/blockfolio.svg";
import coinbase from "../../assets/images/coinbase.svg";
import coingecko from "../../assets/images/coingecko.svg";
import coinmarketcap from "../../assets/images/coinmarketcap.svg";
import gateio from "../../assets/images/gateio.svg";
import kraven from "../../assets/images/kraven.svg";
import kucoin from "../../assets/images/kucoin.svg";

export default function Partners() {
  return (
    <>
      <div className="max-w-6xl mx-auto grid grid-cols-2 gap-8 lg:grid-cols-4 px-5">
        <div className="flex justify-center items-center">
          <img src={blockfolio} alt="" />
        </div>
        <div className="flex justify-center items-center">
          <img src={coinbase} alt="" />
        </div>
        <div className="flex justify-center items-center">
          <img src={coingecko} alt="" />
        </div>
        <div className="flex justify-center items-center">
          <img src={coinmarketcap} alt="" />
        </div>
      </div>
      <div className="max-w-4xl mx-auto grid grid-cols-2 gap-8 mt-6 lg:grid-cols-3 px-5">
        <div className="flex justify-center items-center">
          <img src={gateio} alt="" />
        </div>
        <div className="flex justify-center items-center">
          <img src={kraven} alt="" />
        </div>
        <div className="flex justify-center items-center">
          <img src={kucoin} alt="" />
        </div>
      </div>
    </>
  );
}
