import React from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

import Partners from "../../components/Partners";
import Community from "../../components/community";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ["CEX", "LP", "Presale", "Burn"],
  datasets: [
    {
      // label: "# of Votes",
      data: [6.69, 16.8, 34.69, 41.82],
      backgroundColor: ["#D8A9FF", "#FCEAB2", "#FEDEF3", "#C4F7DE"],

      borderWidth: 0,
    },
  ],
};

export default function Whitepaper() {
  return (
    <div data-aos="fade-up" data-aos-duration="800">
      <section className="py-12 max-w-5xl mx-auto px-5">
        <h3 className="text-3xl text-link font-bold">Our Product</h3>
        <p className="text-gray mt-3 leading-7">
          Introducing Nudecoin ($NUDE), a disruptive force within the live cam
          space that is ready to revolutionize the adult entertainment industry.
          Building upon a robust minimum viable product, we already boast
          hundreds of models across our dynamic platform, eagerly waiting for
          the implementation of our groundbreaking payment system.
          <br />
          <br />
          Our platform brings together a suite of features, including fan pages
          for personalized experiences, a tipping system that boosts earnings
          and engagement, messaging features for direct interaction, and most
          importantly, live cams for real-time entertainment. NudeCoin ($NUDE)
          stands at the heart of this transformative experience, powering
          transactions and driving user interactions.
        </p>
      </section>
      <section className="py-12 max-w-5xl mx-auto px-5">
        <h3 className="text-3xl text-link font-bold">The Payment System</h3>
        <p className="text-gray mt-3 leading-7">
          Our innovative payment system bridges the gap between traditional fiat
          and cryptocurrency. Users can purchase website credits using their
          regular debit or credit cards. Behind the scenes, the system purchases
          and subsequently burns $NUDE, thereby increasing the coin's value
          through deflation. Users are then provided with a 1:1 USD value in
          spendable credits for our live cam services.
          <br />
          <br />
          This process offers a seamless and intuitive experience for users,
          elevating the value of our tokens, and bypassing any need for explicit
          cryptocurrency knowledge. Simultaneously, NudeCoin holders can
          directly send their coins to the site, exchanging their cryptocurrency
          for usable credits via their profile wallet. This dual approach
          ensures both crypto enthusiasts and those new to digital currencies
          can benefit from our platform.
        </p>
      </section>
      <section className="py-12 max-w-5xl mx-auto px-5">
        <h3 className="text-3xl text-link font-bold">Our Talent</h3>
        <p className="text-gray mt-3 leading-7">
          Our platform caters to a broad spectrum of talents within the NSFW
          content creation space. From budding new talents to the top 1%
          creators on platforms like OnlyFans, we're creating an inclusive and
          fair space for all. Our goal is to empower adult content creators to
          unlock their maximum earning potential.
          <br />
          <br />
          Unlike competitors who may take 20% or more in commissions, Nude takes
          a mere 5% of the revenue. Additionally, we are committed to
          dismantling the typical barriers to entry, offering fair opportunities
          to newcomers. Our algorithm ensures balanced visibility, giving due
          coverage to smaller, niche content creators, alongside those with
          large, established audiences.
        </p>
      </section>
      <section className="py-12 max-w-5xl mx-auto px-5">
        <h3 className="text-3xl text-link font-bold">Our Product</h3>
        <p className="text-gray mt-3 leading-7">
          In 2023, the global adult entertainment industry is valued at an
          impressive $97 billion and continues to grow at a steady rate of 8%
          yearly. Live cam sites, currently contributing $2 billion, are
          expanding at a remarkable 20% annually.
          <br />
          <br />
          This sector's growth is fueled by technological advancements,
          increased isolation due to the global pandemic, and evolving societal
          views on adult content. Indications suggest a promising future with
          more room for growth, expansion, and innovation. NudeCoin aims to lead
          this growth, pioneering new technology and shaping the industry's
          future.
        </p>
      </section>
      <section className="py-12 max-w-5xl mx-auto px-5">
        <h3 className="text-3xl text-link font-bold">
          Why Cryptocurrency Payments?
        </h3>
        <p className="text-gray mt-3 leading-7">
          The reticence of the banking sector towards the adult entertainment
          industry necessitates an alternative solution, and cryptocurrency
          steps in to fill this gap. Decentralization ensures privacy and
          provides a way around the banking barriers, making it an appealing
          payment method in the growing $97 billion sector.
          <br />
          <br />
          Cryptocurrency transactions are currently growing at a rate of 15%
          annually within the adult entertainment industry, bypassing scrutiny
          and sanctions from traditional banking systems. This growth points to
          a significant shift in the financial landscape, posing a challenge to
          traditional banking, and paving the way for an era of financial
          inclusivity.
        </p>
      </section>
      <section className="py-12 max-w-5xl mx-auto px-5">
        <h3 className="text-3xl text-link font-bold">Our Team</h3>
        <p className="text-gray mt-3 leading-7">
          NudeCoin's team is our greatest asset, boasting expertise and rich
          connections within the crypto sector. Led by two veterans from
          CumRocket's core team, our seasoned professionals provide strategic
          direction and valuable insights. Our network extends to Tier 1
          exchanges and crypto influencers, equipping us to lead NudeCoin as an
          industry trailblazer.
          <br />
          <br />
          Our team, comprised of innovative Developers, a compliance-focused
          Legal team, a dedicated Shill team, and insightful Advisors, all
          contribute to NudeCoin's vision of reshaping the adult entertainment
          industry. Their combined passion and dedication fuel our ambitions.
        </p>
      </section>
      {/* <section className="py-12 max-w-5xl mx-auto px-5">
        <h3 className="text-3xl text-link font-bold">Tokenomics</h3>
        <p className="text-gray mt-3 leading-7">
          Introducing Nudecoin ($NUDE), a disruptive force within the live cam
          space that is ready to revolutionize the adult entertainment industry.
          Building upon a robust minimum viable product, we already boast
          hundreds of models across our dynamic platform, eagerly waiting for
          the implementation of our groundbreaking payment system.
        </p>
      </section>
      <section className="py-12 max-w-5xl mx-auto px-5">
        <h4 className="text-center text-link font-bold text-xl">
          Total Supply: 169,696,969,696 $NUDE
        </h4>
        <ul className="mb-16 mt-12 text-lg grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-4">
          <li className="text-center">
            <span className="text-gradient font-bold">Presale:</span>{" "}
            58,867,878,788
          </li>
          <li className="text-center">
            <span className="text-gradient font-bold">LP:</span> 28,509,090,909
          </li>
          <li className="text-center">
            <span className="text-gradient font-bold">CEX:</span> 11,352,727,273
          </li>
          <li className="text-center">
            <span className="text-gradient font-bold">Burn:</span>{" "}
            70,967,272,727
          </li>
        </ul>
        <div className="max-w-lg mx-auto mt-12 mb-16 px-5">
          <Pie
            data={data}
            width="80%"
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      return `${context?.parsed}%`;
                    },
                  },
                },
              },
            }}
          />
        </div>
      </section> */}

      <div data-aos="fade-right" data-aos-duration="800">
        <Partners />
      </div>

      <Community />
    </div>
  );
}
